import { usePage } from "@inertiajs/inertia-vue3";

export default function can(key){ 
    let scopes = null;
    if (this?.$page !== undefined) {
        scopes = this.$page.props.auth.scopes;
    } else {
      scopes = usePage().props.value.auth.scopes;
    }
    
    if (scopes === undefined) {
        return false;
    }

    return scopes.indexOf(key) > -1;
}